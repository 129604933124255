import StatementsIcon from '@mui/icons-material/AccountBalance';
import SuppliersIcon from '@mui/icons-material/Apartment';
import FormsIcon from '@mui/icons-material/Assignment';
import LeadsIcon from '@mui/icons-material/BarChart';
import BookingsIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import TripsIcon from '@mui/icons-material/Flight';
import HomeIcon from '@mui/icons-material/Home';
import ClientPaymentsIcon from '@mui/icons-material/Payments';
import ClientsIcon from '@mui/icons-material/People';
import UnreconciledIcon from '@mui/icons-material/Receipt';
import PaymentsIcon from '@mui/icons-material/ReceiptLong';
import SchemaIcon from '@mui/icons-material/Schema';
import AnalyticsIcon from '@mui/icons-material/ShowChart';
import type { SvgIcon } from '@mui/material';
import type { UserInfoResponseDto } from 'dtos';

type TripSuitePage = {
  name: string;
  path: string;
  IconComponent: typeof SvgIcon;
  shouldShow?: (userInfo: UserInfoResponseDto | undefined) => boolean;
  beta?: boolean;
};

export const pages: TripSuitePage[] = [
  {
    name: 'Dashboard',
    IconComponent: HomeIcon,
    path: '/',
  },
  {
    name: 'Trips',
    IconComponent: TripsIcon,
    path: '/trips',
  },
  {
    name: 'Bookings',
    IconComponent: BookingsIcon,
    path: '/bookings',
  },
  {
    name: 'Clients',
    IconComponent: ClientsIcon,
    path: '/clients',
  },
  {
    name: 'Suppliers',
    IconComponent: SuppliersIcon,
    path: '/suppliers',
  },
  {
    name: 'Client Payments',
    IconComponent: PaymentsIcon,
    path: '/payments',
  },
  {
    name: 'Supplier Payments',
    IconComponent: ClientPaymentsIcon,
    path: '/supplier-payments',
    shouldShow: (userInfo: UserInfoResponseDto | undefined) =>
      (userInfo?.isOrgUser && !userInfo.isOrgAssistant) ?? false,
  },
  {
    name: 'Commissions',
    IconComponent: UnreconciledIcon,
    path: '/commissions',
    shouldShow: (userInfo: UserInfoResponseDto | undefined) =>
      !userInfo?.organization?.id ||
      userInfo.organization.id !== 'e3910ae5-3143-4637-a13b-b3fb4a361750',
  },
  {
    name: 'Statements',
    IconComponent: StatementsIcon,
    path: '/statements',
    shouldShow: (userInfo: UserInfoResponseDto | undefined) =>
      !!userInfo?.statementsEnabled,
  },
  {
    name: 'Leads',
    IconComponent: LeadsIcon,
    path: '/leads',
  },
  ...(process.env.NEXT_PUBLIC_FORMS_ENABLED
    ? [
        {
          name: 'Forms',
          IconComponent: FormsIcon,
          path: '/forms',
        },
      ]
    : []),
  ...(process.env.NEXT_PUBLIC_ANALYTICS_ENABLED
    ? [
        {
          name: 'Analytics',
          IconComponent: AnalyticsIcon,
          path: '/analytics',
        },
      ]
    : []),
  ...(process.env.NEXT_PUBLIC_WORKFLOWS_ENABLED === '1'
    ? [
        {
          name: 'Workflows',
          IconComponent: SchemaIcon,
          path: '/workflows',
          shouldShow: (userInfo: UserInfoResponseDto | undefined) =>
            !!userInfo?.isOrgUser,
        },
      ]
    : []),
];
