import type { ErrorResponseDto } from 'dtos';
import _ky from 'ky';

import { enqueueSnackbar as toast } from 'notistack';

const showGenericError = () => {
  toast('An error occurred while handling your request.  Please try again.', {
    variant: 'error',
  });
};

const ky = _ky.create({
  retry: {
    limit: 0, // Disable retries
  },
  timeout: 60000,
  hooks: {
    afterResponse: [
      async (_request, _options, response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // Skip showing toast for 401 errors
            return Promise.reject();
          }

          try {
            const errorResponse = await response.json();
            const { type, message } = errorResponse as ErrorResponseDto;

            if (['error', 'warning'].includes(type)) {
              toast(message, { variant: type });
            } else {
              showGenericError();
            }

            return Promise.reject(errorResponse);
          } catch (error) {
            showGenericError();
            return Promise.reject(error);
          }
        }
      },
    ],
  },
});

export default ky;
