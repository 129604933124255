import { Box } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import type { HierarchicalAgency } from 'components/types';
import { getInitials } from 'utils/client/contextTree';

import ContextAutoCompleteUserNode from './ContextAutoCompleteUserNode';
import ContextPickerBadge from './ContextPickerBadge';

function ContextAutoCompleteNode({
  node,
  query,
  onSelect,
}: {
  node: HierarchicalAgency;
  query: string;
  onSelect: ({
    agencyId,
    userId,
  }: {
    agencyId: string;
    userId?: string;
  }) => void;
}) {
  const initials = getInitials(node.name);

  const matches = match(node.name, query, {
    findAllOccurrences: true,
    insideWords: true,
  });
  const parts = parse(node.name, matches);

  const userMatches = match(node.users[0]?.name || '', query, {
    findAllOccurrences: true,
    insideWords: true,
  });
  const userParts = parse(node.users[0]?.name || '', userMatches);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          ml: `${20 * node.depth}px`,
          p: 1,
          '&:hover': {
            backgroundColor: 'secondary.light',
            cursor: 'pointer',
          },
        }}
        onClick={() => onSelect({ agencyId: node.id })}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            width: 40,
            backgroundColor: node.color,
            color: '#fff',
            mr: 2,
            borderRadius: 1,
            zIndex: 1,
          }}
        >
          {initials}
          {/* if there's only one user show then show their initials alongside the agency */}
          {node.singleUserAgency && node.users.length > 0 && (
            <ContextPickerBadge
              color={node.color}
              text={getInitials(node.users[0].name || '')}
              sx={{
                bottom: -10,
                right: -10,
              }}
            />
          )}
        </Box>
        <Box display={'flex'} flexDirection="column">
          <Box sx={{ fontSize: node.singleUserAgency ? 12 : 14 }}>
            {parts.map((part, index) => (
              <span
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text.replace(/ /g, '\u00A0')}
              </span>
            ))}
          </Box>
          {node.singleUserAgency && (
            <Box sx={{ fontSize: 14 }}>
              {userParts.map((part, index) => (
                <span
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text.replace(/ /g, '\u00A0')}
                </span>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      {!node.singleUserAgency &&
        node.users.map((user) => (
          <ContextAutoCompleteUserNode
            key={`${node.id} ${user.agencyUserId}`}
            node={{ ...node, depth: node.depth + 1 }}
            user={user}
            query={query}
            onSelect={({ agencyId, userId }) => {
              onSelect({ agencyId, userId });
            }}
          />
        ))}
      {node.children.map((node) => (
        <ContextAutoCompleteNode
          onSelect={({ agencyId, userId }) => {
            onSelect({ agencyId, userId });
          }}
          key={node.id}
          node={node}
          query={query}
        />
      ))}
    </>
  );
}
export default ContextAutoCompleteNode;
