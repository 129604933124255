import type { ClientResponseDto } from 'dtos';
import useSWR from 'swr';
import { getUrlSearchParams } from 'utils/client/fetch';
import type { PaginatedData, SortModel } from '../types/paginatedData';
import { fetcher } from '../utils/client/swr/jsonFetcher';

type UseClientsProps = {
  query?: string;
  tripId?: string;
  page?: number;
  pageSize?: number;
  sortModel?: SortModel;
  whatFor?: string;
  active?: string;
  groupId?: string;
  assignedToAgencyUserId?: string;
};

export default function useClients({
  query = '',
  page = 0,
  pageSize = 20,
  sortModel: { sort, sortDirection } = { sort: 'name', sortDirection: 'asc' },
  whatFor,
  active,
  groupId,
  assignedToAgencyUserId,
}: UseClientsProps) {
  const queryParams = getUrlSearchParams({
    query: query,
    page: page.toString(),
    pageSize: pageSize.toString(),
    sort,
    sortDirection,
    whatFor,
    active,
    groupId,
    assignedToAgencyUserId,
  });

  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<PaginatedData<ClientResponseDto>, Error>(
    `clients?${queryParams.toString()}`,
    fetcher,
  );

  const { data: clients = [], meta } =
    (data as PaginatedData<ClientResponseDto>) || {};

  return { clients, meta, isLoading, error, refresh };
}
