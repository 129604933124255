import { useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function SignedOutRedirect() {
  const router = useRouter();
  const clerkUser = useUser();
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!router.asPath.startsWith('/login') && clerkUser.isSignedIn === false) {
      const redirectUrl = `/login?redirect=${encodeURIComponent(router.asPath)}`;
      router.push(redirectUrl);
    }
  }, [clerkUser, router.asPath]);

  return null;
}
