import { useUser } from '@clerk/clerk-react';
import { Box, Stack } from '@mui/material';
import T from 'components/common/T';
import AppBar from 'components/layout/AppBar';
import Drawer from 'components/layout/Drawer';
import PageContent from 'components/layout/PageContent';
import UserInfoContext from 'context/user-info.context';
import type { UserInfoResponseDto } from 'dtos';
import { ConfirmProvider } from 'material-ui-confirm';
import type { NextComponentType, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const AppContent = ({
  userInfo,
  Component,
  pageProps,
}: {
  userInfo: UserInfoResponseDto | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  Component: NextComponentType<NextPageContext, any, any>;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  pageProps: any;
}) => {
  const router = useRouter();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const noPaddingViews = [
    '/forms/[id]',
    '/trips/[tripId]/invoices/[id]',
    '/r/[retoolAppId]',
  ];
  const noPadding = noPaddingViews.some((view) => view === router.pathname);

  return (
    <UserInfoContext.Provider value={userInfo}>
      {!userInfo && (
        <T variant="body2" color="secondaryText" p={1}>
          Loading app...
        </T>
      )}
      {userInfo && (
        <Stack direction="row">
          <AppBar open={drawerOpen} setOpen={setDrawerOpen} />
          <Drawer open={drawerOpen} setOpen={setDrawerOpen} />
          <PageContent noPadding={noPadding}>
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: {
                  variant: 'contained',
                  color: 'primary',
                },
                confirmationText: 'Confirm',
              }}
            >
              <Component {...pageProps} />
              <Box
                id="add-button"
                sx={{
                  position: 'fixed',
                  display: 'flex',
                  justifyContent: 'center',
                  width: 56,
                  bottom: 8,
                  right: 0,
                }}
              />
            </ConfirmProvider>
          </PageContent>
        </Stack>
      )}
    </UserInfoContext.Provider>
  );
};

export default AppContent;
